$(function() {
  if($('.header').length && !$('.header').hasClass('header--static')) {

    let header = $('.header');

    $(window).scroll(function() {
      if($(this).scrollTop() > 1) {
       header.addClass('header_fixed');
      } else {
       header.removeClass('header_fixed');
      }
    });
    $(window).scroll();
  }



  //burger
  const menu = $('.js-menu');
  const html = $('html');

  $('.js-openMenu').on('click', function() {
    // menu.removeClass('hide_block');
    // menu.toggleClass('is_open');
    html.toggleClass('menu_open');

    // $('.header').removeClass('nav-up');
  });

  $('.js-closeMenu, .js-menuOverlay').on('click', function() {
    // menu.addClass('hide_block');

    // setTimeout(function() {
      // menu.removeClass('is_open');
      html.removeClass('menu_open');
    // }, 200)
  });
 });
