$('.js-scrollToElem').click( function(event){ // ловим клик по ссылке с классом go_to
  event.preventDefault();
	var scroll_el = $(this).attr('href'); // возьмем содержимое атрибута href, должен быть селектором, т.е. например начинаться с # или .
  var header =  $('.header');
		if ($(scroll_el).length != 0) { // проверим существование элемента чтобы избежать ошибки
			$('html, body').animate({ scrollTop: $(scroll_el).offset().top-$(header).innerHeight()}, 600); // анимируем скроолинг к элементу scroll_el
		}

		if ($(this).hasClass('js-questionlink')) {
			$('.js-selectShowAll').val(1).change();
		}

	return false; // выключаем стандартное действие
});

$(document).on('click', '.js-scrollToElemForModal', function(event){ // ловим клик по ссылке с классом go_to
  event.preventDefault();
	var scroll_el = $(this).attr('href'); // возьмем содержимое атрибута href, должен быть селектором, т.е. например начинаться с # или .
  var header =  $('.header');
		if ($(scroll_el).length != 0) { // проверим существование элемента чтобы избежать ошибки
      $('.js-modalAnimateClose').trigger('click');
			$('html, body').animate({ scrollTop: $(scroll_el).offset().top-$(header).innerHeight()}, 500); // анимируем скроолинг к элементу scroll_el
		}

		// if ($(this).hasClass('js-questionlink')) {
		// 	$('.js-selectShowAll').val(1).change();
		// }

	return false; // выключаем стандартное действие
});


$(document).on('click', '.js-scrollToElemFromMenu', function(event){ // ловим клик по ссылке с классом go_to
  event.preventDefault();

	var scroll_el = $(this).attr('href'); // возьмем содержимое атрибута href, должен быть селектором, т.е. например начинаться с # или .
  var header =  $('.header');
		if ($(scroll_el).length != 0) { // проверим существование элемента чтобы избежать ошибки
      $('.js-openMenu').trigger('click');
      setTimeout(function() {
        $('html, body').animate({ scrollTop: $(scroll_el).offset().top-$(header).innerHeight()}, 600); // анимируем скроолинг к элементу scroll_el
      }, 100);
		}

		// if ($(this).hasClass('js-questionlink')) {
		// 	$('.js-selectShowAll').val(1).change();
		// }

	return false; // выключаем стандартное действие
});


$(document).on('click', '.header__logo-link', function(event){ // ловим клик по ссылке с классом go_to
  event.preventDefault();
        $('html, body').animate({ scrollTop: 0}, 600); // анимируем скроолинг к элементу scroll_el
	return false; // выключаем стандартное действие
});
