// import Swiper from 'swiper/bundle';
// import Swiper from '../../../node_modules/swiper/swiper-bundle.min.js';

if ($('.js-newsSlider').length > 0) {
	let sliderNews = new Swiper('.js-newsSlider', {
		// slidesPerView: 3,
    // spaceBetween: 32,
		speed: 300,
		// effect: 'fade',
		// autoHeight: true,
    observer: true,
    observeParents: true,
    navigation: {
      nextEl: ".js-newsSliderNextBtn",
      prevEl: ".js-newsSliderPrevBtn",
    },
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 'auto',
        spaceBetween: 8
      },
      // when window width is >= 480px
      576: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      // when window width is >= 640px
      992: {
        slidesPerView: 3,
        spaceBetween: 32,
      }
    }
	});
}
