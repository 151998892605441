
// Группы объектов
// var groups = [
//   {
//     name: "Объекты",
//     style: "islands#redIcon",
//     items: [
// //       {
// //         center: [55.737994, 37.661386],
// //         name: "Объект 1",
// //         description: "<p>Подведенная мощность 30 МВт</p><p>1-я категория надежности электроснабжения</p><p>Система автоматического пожаротушения и оповещения</p><p>Расположение внутри МКАД</p><p>Паркинг для резидентов</p>",
// //         link: "ajax-project-detail-modal.html",
// //       },
// //       {
// //         center: [55.767688, 37.668800],
// //         name: "Объект 2",
// //         description: "<p>text text</p>",
// //         link: "ajax-project-detail-modal.html",
// //       },
// //       {
// //         center: [55.743908, 37.566385],
// //         name: "ЦОД на Вилюйской улице",
// //         description: "<p>Подведенная мощность 30 МВт</p><p>1-я категория надежности электроснабжения</p><p>Система автоматического пожаротушения и оповещения</p><p>Расположение внутри МКАД</p><p>Паркинг для резидентов</p>",
// //         link: "ajax-project-detail-modal.html",
// //       },
//       {
//         center: [56.007059, 37.154958],
//         name: "Алабушево - Технополис «Москва»",
//         description: "<p>Площадь 106 100 м<sup>2</sup></p>",
//         link: "ajax-project-detail-modal.html",
//       },
//       {
//         center: [55.888740, 37.430390],
//         name: "Химки",
//         description: "<p>Площадь 28 560 м<sup>2</sup></p>",
//         link: "ajax-project-detail-modal.html",
//       },
//       {
//         center: [55.883570, 37.629439],
//         name: "Вилюйская,9",
//         description: "<p>Площадь 28 000 м<sup>2</sup></p>",
//         link: "ajax-project-detail-modal.html",
//       },
//       {
//         center: [55.635640, 37.636590],
//         name: "Промышленная, 6",
//         description: "<p>Площадь 10 500 м<sup>2</sup></p>",
//         link: "ajax-project-detail-modal.html",
//       },
//       {
//         center: [55.572043, 37.603892],
//         name: "Подольских курсантов",
//         description: "<p>Площадь 3 174 м<sup>2</sup></p>",
//         link: "ajax-project-detail-modal.html",
//       },
//       {
//         center: [55.548461, 37.075072],
//         name: "Крекшино",
//         description: "<p>Площадь 16 264 м<sup>2</sup></p>",
//         link: "ajax-project-detail-modal.html",
//       }
//     ]
//   },
// ];


ymaps.ready(init);
function generateBaloon(marker){
  let tpl = '';
  if (!!marker.link) {
    tpl = `<div class="baloon-card">
      <div class="baloon-card__box">
        <div class="baloon-card__title">${marker.name}</div>
        <div class="baloon-card__description">${marker.description}</div>
        <div class="baloon-card__link-wrapper">
          <a class="baloon-card__link js-modalAnimateLink" href="javascript:;" data-mfp-ajax="ajax" data-mfp-src="${marker.link}">Открыть детали проекта</a>
        </div>
      </div>
    </div>`;
  } else {
    tpl = `<div class="baloon-card">
      <div class="baloon-card__box">
        <div class="baloon-card__title">${marker.name}</div>
        <div class="baloon-card__description">${marker.description}</div>
      </div>
    </div>`;
  }
  return tpl;
}

function init() {

  // Создание экземпляра карты.
  var myMap = new ymaps.Map('map-box', {
    // center: [55.767688, 37.668800],
    center: [55.752913, 37.617234],
    zoom: 9
  }, {
    searchControlProvider: 'yandex#search'
  }),
    // Контейнер для меню.
    menu = $('<ul class="menu" style="display:none"/>');

  //отключаем зум колёсиком мышки
  myMap.behaviors.disable('scrollZoom');

  if (Modernizr.touchevents) {
    myMap.behaviors.disable('drag');
  }

  console.log('window.groups = ',window.groups);
  var groups2 = window.groups;
  if (typeof(groups2) == "undefined" || !groups2.length) {
    console.error('на странице не обнаружена переменная с метками - window.groups(см.html)')
    return 0;
  }
  console.log('groups2.length = ',groups2.length);
  for (var i = 0, l = groups2.length; i < l; i++) {
    createMenuGroup(groups2[i]);
  }

  function createMenuGroup(group) {
    // Пункт меню.
    var menuItem = $('<li><a href="#">' + group.name + '</a></li>'),
      // Коллекция для геообъектов группы.
      collection = new ymaps.GeoObjectCollection(null, { preset: group.style }),
      // Контейнер для подменю.
      submenu = $('<ul class="submenu"/>');

    // Добавляем коллекцию на карту.
    myMap.geoObjects.add(collection);
    // Добавляем подменю.
    menuItem
      .append(submenu)
      // Добавляем пункт в меню.
      .appendTo(menu)
      // По клику удаляем/добавляем коллекцию на карту и скрываем/отображаем подменю.
      .find('a')
      .bind('click', function () {
        if (collection.getParent()) {
          myMap.geoObjects.remove(collection);
          submenu.hide();
        } else {
          myMap.geoObjects.add(collection);
          submenu.show();
        }
      });
    for (var j = 0, m = group.items.length; j < m; j++) {
      createSubMenu(group.items[j], collection, submenu);
    }
  }

  function createSubMenu(item, collection, submenu) {
    // Пункт подменю.
    var submenuItem = $('<li><a href="#">' + item.name + '</a></li>'),
      // MyIconContentLayout = ymaps.templateLayoutFactory.createClass(
      //   `<div class="baloon-custom" style="background-color: transparent;width: 104px;height: 112px;filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.24));">
      //     <svg xmlns="http://www.w3.org/2000/svg" class="icon-baloon" width="104" height="112" viewBox="0 0 104 112" fill="none" style="&#10;"><rect width="104" height="104" fill="white"/><path d="M63.5189 60.3907H59.7221V43.6098H63.5189V60.3907ZM38.5114 58.3018C37.0905 56.8229 36.3683 54.7108 36.3683 52.1057V51.9179C36.3373 50.778 36.5314 49.6432 36.9394 48.5795C37.3473 47.5158 37.9609 46.5444 38.7447 45.7217C39.5198 44.9497 40.4407 44.3421 41.453 43.935C42.4653 43.5279 43.5483 43.3295 44.638 43.3516C46.4407 43.2807 48.2165 43.8084 49.6926 44.8535C50.3799 45.4122 50.9426 46.1106 51.3439 46.9032C51.7452 47.6957 51.9761 48.5647 52.0215 49.4533H48.1551C47.8755 47.4115 46.7806 46.3555 44.6609 46.3555C42.029 46.3555 40.3519 48.3972 40.3519 51.8943V52.082C40.3519 55.5791 41.9124 57.5741 44.6844 57.5741C46.7576 57.5741 48.2019 56.5181 48.5045 54.3588H52.208C52.1557 55.2618 51.92 56.1443 51.5153 56.9517C51.1107 57.7591 50.5457 58.4741 49.8555 59.0525C48.3785 60.159 46.5699 60.7223 44.7307 60.6488C42.0055 60.6488 39.9094 59.8037 38.5114 58.3018ZM73.8256 64.8001H30.1759V39.1983H73.8256V64.8001ZM27 68H77V36H27V68Z" fill="#005DAD"/><path d="M0 104H8L0 112V104Z" fill="white"/></svg>
      //     </div>`
      // ),
      MyIconContentLayout = ymaps.templateLayoutFactory.createClass(
        `<div class="baloon-custom" style="background-color: transparent;width: 82px;height: 78px;filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.24));">
          <svg width="82" height="78" viewBox="0 0 82 78" class="icon-baloon" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="82" height="72.4286" fill="white"/>
            <path d="M0 71.4286H6.30769L0 77V71.4286Z" fill="white"/>
            <path d="M52.5189 44.391H48.7221V27.61H52.5189V44.391ZM27.5114 42.302C26.0905 40.8232 25.3683 38.7111 25.3683 36.1059V35.9181C25.3373 34.7782 25.5314 33.6434 25.9394 32.5798C26.3473 31.5161 26.9609 30.5447 27.7447 29.722C28.5198 28.9499 29.4407 28.3424 30.453 27.9353C31.4653 27.5282 32.5483 27.3298 33.638 27.3518C35.4407 27.281 37.2165 27.8086 38.6926 28.8538C39.3799 29.4124 39.9426 30.1108 40.3439 30.9034C40.7452 31.696 40.9761 32.5649 41.0215 33.4535H37.1551C36.8755 31.4117 35.7806 30.3557 33.6609 30.3557C31.029 30.3557 29.3519 32.3975 29.3519 35.8945V36.0823C29.3519 39.5793 30.9124 41.5744 33.6844 41.5744C35.7576 41.5744 37.2019 40.5183 37.5045 38.3591H41.208C41.1557 39.262 40.92 40.1446 40.5153 40.952C40.1107 41.7593 39.5457 42.4744 38.8555 43.0527C37.3785 44.1592 35.5699 44.7226 33.7307 44.6491C31.0055 44.6491 28.9094 43.804 27.5114 42.302ZM62.8256 48.8004H19.1759V23.1985H62.8256V48.8004ZM16 52.0002H66V20.0002H16V52.0002Z" fill="#005DAD"/>
          </svg>
          </div>`
      ),


      baloonItem = generateBaloon(item);
      // console.log("baloonItem = ",baloonItem);
      var placemark = new ymaps.Placemark(item.center, {
        balloonContent: baloonItem,
      },
        {
          // Опции.
          // // Необходимо указать данный тип макета.
          // iconLayout: 'default#image',
          // // iconLayout: squareLayout,

          // Необходимо указать данный тип макета.
          iconLayout: 'default#imageWithContent',

          // Своё изображение иконки метки.
          // iconImageHref: '/images/svg/baloon.svg',

          iconImageHref: '',
          iconContent: 'images/svg/baloon.svg',

          // Размеры метки.
          // iconImageSize: [30, 42],
          // iconImageSize: [104, 112],
          iconImageSize: [82, 78],
          // Смещение левого верхнего угла иконки относительно
          // её "ножки" (точки привязки).
          // iconImageOffset: [-5, -38],
          // iconImageOffset: [0, -112],
          iconImageOffset: [0, -78],
          // Макет содержимого.
          iconContentLayout: MyIconContentLayout,
          // balloonOffset: [57, 0],
        });

    // Добавляем метку в коллекцию.
    collection.add(placemark);


    if (!Modernizr.touchevents) {
      console.log('touchevents - not-supported');
      // not-supported
      placemark.events.add('mouseenter', function(event){
          console.log('placemark.balloon open =', placemark.balloon);
          placemark.balloon.open();
      });
      placemark.balloon.events.add('mouseleave', function(event){
          console.log('placemark.balloon close =', placemark.balloon);
          placemark.balloon.close();
      });
    } else {
      // supported
      console.log('touchevents - supported');
    }
    // myMap.events.add('dblclick', function () {
    //   console.log('dblclick');
    // });

    // Добавляем пункт в подменю.
    submenuItem
      .appendTo(submenu)
      // При клике по пункту подменю открываем/закрываем баллун у метки.
      .find('a')
      .bind('click', function () {
        if (!placemark.balloon.isOpen()) {
          placemark.balloon.open();
        } else {
          placemark.balloon.close();
        }
        return false;
      });
  }

  // Добавляем меню в тэг BODY.
  menu.appendTo($('body'));
  // Выставляем масштаб карты чтобы были видны все группы.
  myMap.setBounds(myMap.geoObjects.getBounds(),{checkZoomRange:true, zoomMargin:[30,9]});
}
