$.extend($.validator.messages, {
  required: 'Это поле обязательно для заполнения.',
  remote: 'Пожалуйста, введите правильное значение.',
  email: 'Указан неверный формат e-mail адреса.',
  url: 'Пожалуйста, введите корректный URL.',
  date: 'Пожалуйста, введите корректную дату.',
  dateISO: 'Пожалуйста, введите корректную дату в формате ISO.',
  number: 'Пожалуйста, введите число.',
  digits: 'Пожалуйста, вводите только цифры.',
  creditcard: 'Пожалуйста, введите правильный номер кредитной карты.',
  equalTo: 'Пожалуйста, введите такое же значение ещё раз.',
  extension: 'Пожалуйста, выберите файл с правильным расширением.',
  maxlength: $.validator.format('Максимальная длина описания - {0} символов.'),
  minlength: $.validator.format('Пожалуйста, введите не меньше {0} символов.'),
  rangelength: $.validator.format(
    'Пожалуйста, введите значение длиной от {0} до {1} символов.'
  ),
  range: $.validator.format('Пожалуйста, введите число от {0} до {1}.'),
  max: $.validator.format('Максимальная длина описания - {0} символов.'),
  min: $.validator.format('Пожалуйста, введите число, большее или равное {0}.'),
})

$(document).ready(function () {
  console.log('ready')
});

function validateFormInit() {
  console.log('validateFormInit');
  $('.js-validate').validate({
    ignore: ':hidden',
    errorElement: 'span',
    errorClass: 'label-error',
    highlight: function (element) {
      setTimeout(() => {
        // console.log('$(element).parent()2 = ', $(element).parent());
        if (!$(element).attr('disabled')) {
          $(element).parent().removeClass('is-success')
          $(element).parent().addClass('is-error')

          if (
            $(element).attr('type') == 'checkbox' ||
            $(element).hasClass('select') ||
            $(element).attr('type') == 'radio'
          ) {
            var parent = $(element).parent()
            // console.log('parent2 = ', parent);

            parent.append(parent.find('.label-error'))
          }
        }
      })
    },
    unhighlight: function (element) {
      setTimeout(() => {
        if (!$(element).attr('disabled')) {
          if ($(element).val() != '') $(element).parent().addClass('is-success')
          $(element).parent().removeClass('is-error')
        }
      })
    },
  })
}

$(document).on('validateFormInit', () => {

  validateFormInit()
})

validateFormInit()

$(document).ready(function () {
  console.log('ready3')
});

$(document).on('change input blur', '.form-input', (event) => {
  const _this = $(event.currentTarget)
  if (_this.val() == '') _this.parent().removeClass('is-success')
})

// $(document).on('change', '.js-checkboxAgree', (event) => {
//   const _this = $(event.currentTarget)
//   const form = _this.parents('form')
//   const button = form.find('[type="submit"]')

//   if (_this.is(':checked')) {
//     button.prop('disabled', false)
//   } else {
//     button.prop('disabled', true)
//   }
// })

/*
 * Валидация имени
 */

$.validator.addMethod(
  'namecustom',
  function (value, element) {
    return (
      value.match(/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/) ||
      value.match(/^[а-яА-ЯёЁ]+(?:[\s-][а-яА-ЯёЁ]+)*$/)
    )
  },
  'Указан неверный формат имени.'
)

$.validator.addClassRules('js-name', {
  namecustom: true,
})

if ($('.js-name').length) {
  $('.js-name').on('change keyup input', function () {
    if (this.value.match(/[^а-яА-ЯёЁa-zA-Z\s-]/g)) {
      this.value = this.value.replace(/[^а-яА-ЯёЁa-zA-Z\s-]/g, '')
    }
  })
}

/*
 * Валидация ФИО
 */

$.validator.addMethod(
  'fiocustom',
  function (value, element) {
    if (value == '' && !$(element).prop('required')) return true
    // 'Иванов И.И'.match(/^[а-яА-ЯёЁ]+(?:[\s-.][а-яА-ЯёЁ]+)*$/) || 'Иванов И.И.'.match(/^[а-яА-ЯёЁ]+(?:[\s][а-яА-ЯёЁ]{1}\.[а-яА-ЯёЁ]{1}\.+)*$/)
    // return value.match(/^[а-яА-ЯёЁ]+(?:[\s-][а-яА-ЯёЁ]+)*$/) || value.match(/^[а-яА-ЯёЁ]+(?:[\s][а-яА-ЯёЁ]{1}\.[а-яА-ЯёЁ]{1}\.+)*$/)
    return (
      value.match(/^[а-яА-ЯёЁ]+(?:[\s-][а-яА-ЯёЁ]+)*$/) ||
      value.match(/^[а-яА-ЯёЁ]+(?:[\s][а-яА-ЯёЁ]{1}\.[а-яА-ЯёЁ]{1}\.+)*$/)
    )
  },
  'Указан неверный формат имени.'
)

$.validator.addClassRules('js-fio', {
  fiocustom: true,
})

// if ($('.js-fio').length) {
//   $('.js-fio').on('change keyup input', function () {
//     if (this.value.match(/[^а-яА-ЯёЁ\s-.]/g)) {
//       this.value = this.value.replace(/[^а-яА-ЯёЁ\s-.]/g, '')
//     }
//   })
// }

/*
 * Валидация email custom
 */

// $.validator.addMethod('emailcustom', function(value, element) {
// 	if (value == '' && !$(element).prop("required")) return true;
// 	// 'Иванов И.И'.match(/^[а-яА-ЯёЁ]+(?:[\s-.][а-яА-ЯёЁ]+)*$/) || 'Иванов И.И.'.match(/^[а-яА-ЯёЁ]+(?:[\s][а-яА-ЯёЁ]{1}\.[а-яА-ЯёЁ]{1}\.+)*$/)
// 	// return value.match(/^[а-яА-ЯёЁ]+(?:[\s-][а-яА-ЯёЁ]+)*$/) || value.match(/^[а-яА-ЯёЁ]+(?:[\s][а-яА-ЯёЁ]{1}\.[а-яА-ЯёЁ]{1}\.+)*$/)
// 	return value.match(/^[а-яА-ЯёЁ]+(?:[\s-][а-яА-ЯёЁ]+)*$/) || value.match(/^[а-яА-ЯёЁ]+(?:[\s][а-яА-ЯёЁ]{1}\.[а-яА-ЯёЁ]{1}\.+)*$/)
// }, 'Указан неверный формат имени.');

// $.validator.addClassRules('js-email', {
// 	emailcustom: true
// });

if ($('.js-email').length) {
  $('.js-email').on('change keyup input', function () {
    if (this.value.match(/[а-яА-ЯёЁ]/g)) {
      this.value = this.value.replace(/[а-яА-ЯёЁ]/g, '')
    }
  })
}

/*
 * Валидация телефона
 */

$.validator.addMethod(
  'phonecustom',
  function (value, element) {
    // if(value == '+7 (___) ___-__-__') return true;
    if (
      value == '+7 (___) ___-__-__' ||
      (value == '' && !$(element).prop('required'))
    )
      return true
    return value.match(/^\+7\s\([0-9]{3}\)\s[0-9]{3}\-[0-9]{2}\-[0-9]{2}$/)
  },
  'Указан неверный формат телефона.'
)

$.validator.addClassRules('js-phone', {
  phonecustom: true,
})

/*
 * Валидация площади
 */

$.validator.addMethod(
  'squarecustom',
  function (value, element) {
    // return value.match(/^[0-9]*$/) || value.match(/^[0-9]+(?:[\s][\.\,]{1}\[0-9]{2}\.+)*$/);
  //   return value.match(/^[0-9]*$/) || value.match(/^[0-9]+(?:[\.\,]{1}\[0-9]\.+)*$/);
  // },
    // return value.match(/^[0-9]*$/) || value.match(/^[0-9]+(?:[\.\,]{1}\[0-9]+)*$/);
    // return value.match(/^[0-9]*$/) || value.match(/^[0-9]+([\.\,]{1}[0-9]+)?$/);
    return value.match(/^[0-9]*$/) || value.match(/^[0-9]+([\.\,]{1}[0-9]{1,4})?$/);
  },
  'Указан неверный формат площади.'
);

$.validator.addClassRules('js-onlySquare', {
  squarecustom: true,
});



if ($('.js-onlyNumber').length) {
  $('.js-onlyNumber').bind('change keyup input click', function () {
    if (this.value.match(/[0-9]/)) {
      this.value = this.value.replace(/[^\d]/g, '')
    }
  })
}


// if ($('.js-onlySquare').length) {
//   $('.js-onlySquare').bind('change keyup input click', function () {
//     if (this.value.match(/[0-9\.\,]/)) {
//       this.value = this.value.replace(/[^0-9\.\,]/g,"")
//     }
//   })
// }
// $(document).ready(function () {
//   // $(".js-onlyText").bind("change keyup input click", function () {
//   //   if (this.value.match(/[^а-яА-Яa-zA-Z-\s]/g)) {
//   //     this.value = this.value.replace(/[^а-яА-Яa-zA-Z-\s]/g, "");
//   //   }
//   // });
//   $(".js-onlySquare").bind("change keyup input click", function () {
//     if (this.value.match(/[а-яА-Яa-zA-Z-\s]/g)) {
//       this.value = this.value.replace(/[а-яА-Яa-zA-Z-\s]/g, "");
//     }
//   });
// });

$('.js-validate').on('submit', function (e) {
  e.preventDefault();

  if ($(this).valid()) {
      var form = $('.js-validate');
      console.log('valid -submit');
      // var formdata = false;
      // if (window.FormData) {
      //     formdata = new FormData(form[0]);
      // }
      var formData = new FormData(this);
      console.log('formData = ',formData);
      $.ajax({
          url: $(this).attr('action'),
          type: "POST",
          data: formData || form.serialize(),
          cache: false,
          contentType: false,
          processData: false,
          success: function (res) {
            console.log('ajax_success');
              // $('.js-feedbackForm').hide();
              // $('.js-feedbackSuccess').show();
              $('.js-feedbackSuccess').addClass('modal-ok--show');
              // $(document).trigger('validateFormInit');
              document.querySelector('.js-feedbackForm').reset();
              setTimeout(function() {
                $('.js-feedbackForm').find('.fieldset-line').removeClass('is-fieldset-line');
                $('.js-feedbackForm').find('span.label-error').hide();
                $('.js-feedbackForm').find('.form-field').removeClass('is-error');
                $('.js-feedbackForm').find('.form-field').removeClass('is-focus');
                $('.js-select2Single').change();
                // span.label-error
              });
          },
          error: function (error) {
            console.log('ajax_error');
            // $('.js-feedbackForm').hide();
            $('.js-feedbackError').addClass('modal-ok--show');
            // $(document).trigger('validateFormInit');
          }
      });
  } else {
    console.log('NOT valid -submit');
  }
});

$(document).ready(function () {
  console.log('ready2')
});
