import svg4everybody from 'svg4everybody'
import $ from 'jquery'
import './vendor/modernizr';
import './vendor/jquery-validation';
import './vendor/jquery.inputmask.bundle.min.js';
import './vendor/swiper-bundle.min';
import './vendor/select2.min.js';

svg4everybody()

window.$ = $
window.jQuery = $
