// import Swiper from 'swiper/bundle';
// import Swiper from '../../../node_modules/swiper/swiper-bundle.min.js';

if ($('.js-homeSlider').length > 0) {
	let sliderInfo = new Swiper('.js-homeSlider', {
		slidesPerView: 1,
		speed: 300,
		// effect: 'fade',
		// autoHeight: true,
    observer: true,
    observeParents: true,
    navigation: {
      nextEl: ".js-homeSliderNextBtn",
      prevEl: ".js-homeSliderPrevBtn",
    },
		pagination: {
			el: '.js-homeSliderPagination',
			type: 'bullets',
			clickable: true
		},
	});
  if ($('.js-homeSlider').find('.home-slide').length<2) {
    $('.js-homeSlider').find('.home-scr__pagination').hide();
  }
}
